
import { defineComponent, ref } from "vue";
import { fetchCsv } from "@/data";

interface IndexItem {
  title: string;
  sum: string;
  description: string;
  time: string;
}

export default defineComponent({
  setup() {
    const list = ref<IndexItem[]>([]);

    const fetch = async () => {
      list.value = await fetchCsv<IndexItem>("index.csv");
    };

    fetch();

    return { list };
  }
});
