<template>
  <div class="index">
    <div class="card">
      <h5 class="card-body">目录</h5>
    </div>

    <div class="list-group">
      <router-link
        :to="{ name: 'PlayList', params: { id: item.id } }"
        class="list-group-item list-group-item-action"
        v-for="item in list"
        :key="item.id"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ item.title }}</h5>
          <small>{{ item.sum }}P</small>
        </div>
        <p class="mb-1">
          {{ item.description }}
        </p>
        <small>{{ item.time }}</small>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { fetchCsv } from "@/data";

interface IndexItem {
  title: string;
  sum: string;
  description: string;
  time: string;
}

export default defineComponent({
  setup() {
    const list = ref<IndexItem[]>([]);

    const fetch = async () => {
      list.value = await fetchCsv<IndexItem>("index.csv");
    };

    fetch();

    return { list };
  }
});
</script>
